import classes from "./NevButton.module.scss";

function NevButton(props) {
  const btnClasses = props.isActive
    ? `${classes.button} ${classes.active}`
    : `${classes.button} ${classes.notActive}`;
  return (
    <div
      className={classes.box}
      key={props.index}
      onClick={() => props.setItemIndex(props.index)}
    >
      <div className={btnClasses} />
    </div>
  );
}

export default NevButton;
