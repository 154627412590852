import classes from "./NevButtons.module.scss";
import NevButton from "./NevButton";

function NevButtons(props) {
  return (
    <div
      className={classes.wrapper}
      style={{ width: (props.count + 2) * 20 + "px" }}
    >
      <div
        className={classes.box}
        onClick={() =>
          props.setItemIndex(
            (props.activeIndex - 1 + props.count) % props.count
          )
        }
      >
        <div className={classes.arrowLeft} />
      </div>

      {[...Array(props.count)].map((x, index) => {
        return (
          <NevButton
            isActive={index === props.activeIndex}
            setItemIndex={props.setItemIndex}
            index={index}
          />
        );
      })}
      <div
        className={classes.box}
        onClick={() =>
          props.setItemIndex((props.activeIndex + 1) % props.count)
        }
      >
        <div className={classes.arrowRight} />
      </div>
    </div>
  );
}

export default NevButtons;
