import { useEffect, useState } from "react";
import classes from "./Background.module.scss";

function Background(props) {
  const [shouldRender, setRender] = useState(props.show);
  useEffect(() => {
    if (props.show) setRender(true);
  }, [props.show]);

  const onAnimationEnd = () => {
    if (!props.show) setRender(false);
  };

  return (
    shouldRender && (
      <div className={classes.wrapper}>
        <div
          className={classes.overlay}
          onAnimationEnd={onAnimationEnd}
          style={{
            animation: `${
              props.show ? classes.fadeIn : classes.fadeOut
            } 1s ease`,
          }}
        />
        <video
          autoPlay
          muted
          loop
          className={classes.backgroundVideo}
          style={{
            animation: `${
              props.show ? classes.fadeIn : classes.fadeOut
            } 1s ease`,
          }}
        >
          <source src={"/media/bgVideo.mov"} type="video/mp4" />
        </video>
      </div>
    )
  );
}

export default Background;
