import classes from "./Return.module.scss";
import { useRef } from "react";

function ReturnBtn(props) {
  const slesh1Ref = useRef();
  const slesh2Ref = useRef();

  function onHover() {
    slesh1Ref.current.style.backgroundColor = "rgb(230, 230, 255)";
    slesh2Ref.current.style.backgroundColor = "rgb(230, 230, 255)";
  }

  function onOut() {
    slesh1Ref.current.style.backgroundColor = "rgba(230, 230, 255, 0.5)";
    slesh2Ref.current.style.backgroundColor = "rgba(230, 230, 255, 0.5)";
  }

  return (
    <div
      className={classes.return}
      onClick={props.returnFunction}
      style={{ left: props.left + "px" }}
      onMouseEnter={onHover}
      onMouseLeave={onOut}
    >
      <div
        className={classes.slesh}
        ref={slesh1Ref}
        style={{ transform: "rotate(45deg)" }}
      />
      <div
        className={classes.slesh}
        ref={slesh2Ref}
        style={{ transform: "rotate(-45deg)" }}
      />
    </div>
  );
}

export default ReturnBtn;
