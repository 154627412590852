import React from "react";

import classes from "./Playlist.module.scss";
import WaveSurfer from "./wavesurfer.js";

const songs = require("../../data/songs.json").playlist;

class Playlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      activeSongIndex: 0,
    };
  }

  componentDidMount() {
    const track = document.querySelector("#track");
    this.waveform = WaveSurfer.create({
      barWidth: 2,
      cursorWidth: 1,
      container: "#waveform",
      backend: "WebAudio",
      height: 70,
      fillParent: true,
      progressColor: "rgb(230, 230, 255)",
      responsive: true,
      waveColor: "rgba(230, 230, 255, 0.5)",
      cursorColor: "transparent",
      minPxPerSec: 10,
    });
    this.waveform.load(track);
    this.waveform.on("ready", () => {
      if (this.state.isPlaying) {
        this.waveform.play();
      }
    });
    this.waveform.on("finish", () => {
      this.setState({
        activeSongIndex: (this.state.activeSongIndex + 1) % songs.length,
      });
      setTimeout(
        function () {
          const track = document.querySelector("#track");
          this.waveform.load(track);
        }.bind(this),
        100
      );
    });
  }

  playButtonClicked = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
    this.waveform.playPause();
  };

  songClicked = (index) => {
    this.setState({ activeSongIndex: index });
    setTimeout(
      function () {
        const track = document.querySelector("#track");
        this.waveform.load(track);
      }.bind(this),
      100
    );
  };

  render() {
    return (
      <div className={classes.wrapper}>
        <div className={classes.header}>What I Listen to</div>
        <div className={classes.iconContainer} onClick={this.playButtonClicked}>
          <div
            className={this.state.isPlaying ? classes.stop : classes.play}
          ></div>
        </div>
        <div className="wave" id="waveform" />
        <audio
          id="track"
          src={"./sound/" + songs[this.state.activeSongIndex].song + ".mp3"}
        />
        <div className={classes.songsContainer}>
          {songs.map(({ song, artist, time }, index) => {
            const songClasses =
              index === this.state.activeSongIndex
                ? `${classes.songContainer} ${classes.songActive}`
                : classes.songContainer;
            return (
              <div
                className={songClasses}
                key={"song" + index}
                id={"song" + index}
                onClick={() => this.songClicked(index)}
              >
                <div>{song + " | " + artist}</div>
                <div>{time}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Playlist;
