import React, { useEffect, useState, useRef } from "react";

import classes from "./Item.module.scss";
import Handlers from "./Handlers";
import NevButtons from "./NevButtons";
import ItemGallery from "./ItemGallery";
import Return from "../ReturnBtn/Return";
const data = require("../../data/details.json").contentPreview;

function Item(props) {
  const [shouldRender, setRender] = useState(props.show);
  const linkRef = useRef();
  const headerRef = useRef();
  useEffect(() => {
    if (props.show) setRender(true);
  }, [props.show]);

  const onAnimationEnd = () => {
    if (!props.show) setRender(false);
  };

  function showLink() {
    if (itemData.link) {
      linkRef.current.style.opacity = 1;
      headerRef.current.style.color = "rgb(230, 230, 255)";
      headerRef.current.style.cursor = "pointer";
    }
  }

  function hideLink() {
    linkRef.current.style.opacity = 0;
    headerRef.current.style.color = "rgb(255, 255, 255)";
    headerRef.current.style.cursor = "auto";
  }

  const itemsData =
    props.categoryIndex >= 0 ? data[props.categoryIndex].items : data[0].items;
  const itemData = itemsData[props.itemIndex];

  return (
    shouldRender && (
      <div>
        <Return returnFunction={props.returnPreviews} left="20" />
        <div className={classes.wrapper}>
          <a
            href={itemData.link}
            target="_blank"
            rel="noreferrer"
            ref={headerRef}
            className={classes.header}
            onAnimationEnd={onAnimationEnd}
            onMouseEnter={showLink}
            onMouseLeave={hideLink}
            style={{
              animation: `${
                props.show ? classes.fadeIn : classes.fadeOut
              } 1s ease`,
            }}
          >
            <div>{itemData.title}</div>
            <div
              className={classes.link}
              ref={linkRef}
              style={{
                maskImage: "url(/icons/linkIcon.png)",
                webkitMaskImage: "url(/icons/linkIcon.png)",
              }}
            />
          </a>
          <div
            className={classes.gallery}
            style={{
              animation: `${
                props.show ? classes.fadeIn : classes.fadeOut
              } 1s ease`,
            }}
          >
            <ItemGallery gallery={itemData.gallery} folder={itemData.folder} />
          </div>
          <div
            className={classes.description}
            style={{
              left: "32%",
              width: "30%",
              animation: `${
                props.show ? classes.fadeIn : classes.fadeOut
              } 1s ease`,
            }}
          >
            {itemData.leftParagraph}
          </div>
          <div
            className={classes.description}
            style={{
              left: "65%",
              width: "26%",
              animation: `${
                props.show ? classes.fadeIn : classes.fadeOut
              } 1s ease`,
            }}
          >
            {itemData.rightParagraph}
          </div>
          <div
            className={classes.demo}
            style={{
              animation: `${
                props.show ? classes.fadeIn : classes.fadeOut
              } 1s ease`,
            }}
          >
            <iframe
              allowFullScreen={true}
              className={classes.previewVideo}
              title={itemData.cover}
              src={itemData.cover}
            />
          </div>
        </div>
        <Handlers
          show={props.show}
          categoryIndex={props.categoryIndex}
          changeCategory={props.setCategoryIndex}
          setItemIndex={props.setItemIndex}
        />
        <NevButtons
          count={itemsData.length}
          activeIndex={props.itemIndex}
          setItemIndex={props.setItemIndex}
        />
      </div>
    )
  );
}

export default Item;
