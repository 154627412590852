import Handler from "./Handler";

const data = require("../../data/details.json").contentPreview;

const categories = data.map(({ title }) => {
  return title;
});

function Handlers(props) {
  return (
    <div>
      {categories.map((category, index) => {
        return (
          <Handler
            key={index + "handler"}
            show={props.show}
            title={category}
            isActive={index === props.categoryIndex}
            top={-index * 70 + "px"}
            changeCategory={props.changeCategory}
            setItemIndex={props.setItemIndex}
            index={index}
          />
        );
      })}
    </div>
  );
}

export default Handlers;
