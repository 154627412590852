import Gallery from "react-photo-gallery";
import React, { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import "./ItemGallery.module.scss";

function ItemGallery(props) {
  const PHOTO_SET = props.gallery.map(({ path, width, height }) => {
    return {
      src: "/media" + props.folder + "/" + path,
      width: width,
      height: height,
      title: path + "blahhhhhhhhh",
    };
  });

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <Gallery
        photos={PHOTO_SET}
        targetRowHeight={140}
        margin={4}
        direction={"row"}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={PHOTO_SET.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
export default ItemGallery;
