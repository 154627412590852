import { useState } from "react";
import classes from "./Details.module.scss";

const detailsData = require("../../data/details.json").details;

function Details(props) {
  const [hoverList, setHoverList] = useState([]);

  function hoverIn(index) {
    var newHoverList = [false, false, false, false, false];
    newHoverList[index] = true;
    setHoverList(newHoverList);
  }

  function hoverOut() {
    setHoverList([]);
  }

  function clicked(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        alert("Copied the text:" + text);
      },
      function () {
        alert("Not Copied the text:" + text);
      }
    );
  }
  return (
    <div
      className={classes.detailsContainer}
      style={{
        transform: "translate(" + props.transform + ")",
      }}
    >
      <div className={classes.details}>
        {detailsData.map(({ type, detail, url }, index) => {
          const isActive =
            hoverList.length > 0 && !hoverList[index] ? false : true;
          if (url != "") {
            return (
              <a href={url} key={type} target="_blank" rel="noreferrer">
                <div
                  className={
                    isActive
                      ? `${classes.icon} ${classes.iconActive}`
                      : `${classes.icon} ${classes.iconNotActive}`
                  }
                  onMouseEnter={() => hoverIn(index)}
                  onMouseOut={hoverOut}
                  style={{
                    WebkitMaskImage: "url(/icons/" + type + "Icon.png)",
                    maskImage: "url(/icons/" + type + "Icon.png)",
                  }}
                />
                {isActive && hoverList.length > 0 && (
                  <div className={classes.hoverDetails}>{detail}</div>
                )}
              </a>
            );
          } else {
            return (
              <div style={{ position: "relative" }}>
                <div
                  className={
                    isActive
                      ? `${classes.icon} ${classes.iconActive}`
                      : `${classes.icon} ${classes.iconNotActive}`
                  }
                  onMouseEnter={() => hoverIn(index)}
                  onMouseOut={hoverOut}
                  onClick={() => clicked(detail)}
                  style={{
                    WebkitMaskImage: "url(/icons/" + type + "Icon.png)",
                    maskImage: "url(/icons/" + type + "Icon.png)",
                  }}
                />
                {isActive && hoverList.length > 0 && (
                  <div className={classes.hoverDetails}>{detail}</div>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Details;
