import classes from "./AboutMe.module.scss";

import Playlist from "./Playlist";
import Travels from "./Travels";
import Timeline from "./Timeline";
import Freetime from "./Freetime";
import Return from "../ReturnBtn/Return";

function AboutMe(props) {
  return (
    <div>
      <Return left="-50" returnFunction={props.returnFunction} />
      <div className={classes.textWrapper}>
        <Playlist />
        <Travels />
        <Timeline />
        <Freetime />
      </div>
    </div>
  );
}

export default AboutMe;
