import classes from "./Freetime.module.scss";
import { PieChart, LabelRenderProps } from "react-minimal-pie-chart";
import { useState } from "react";

const freetimeData = require("../../data/details.json").freetime;

function Freetime(props) {
  const [hovered, setHovered] = useState(undefined);

  const data = freetimeData.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        title: "",
        color: "rgb(230, 230, 255)",
      };
    }
    return entry;
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>What I Like</div>
      <PieChart
        className={classes.pie}
        data={data}
        lineWidth={50}
        paddingAngle={2}
        animate="true"
        animationDuration="600"
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(undefined);
        }}
        label={(p) => {
          return hovered === p.dataIndex ? freetimeData[p.dataIndex].title : "";
        }}
        labelStyle={{
          fontSize: "4pt",
          pointerEvents: "none",
        }}
        labelPosition={75}
      />
      ;
    </div>
  );
}

export default Freetime;
