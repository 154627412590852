import React from "react";
import classes from "./Travels.module.scss";

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
  Annotation,
} from "react-simple-maps";

const mapData = require("../../data/places.json").places;

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

class Travels extends React.Component {
  constructor(props) {
    super(props);
    this.descriptionRef = React.createRef();
    this.minusRef = React.createRef();
    this.plus1Ref = React.createRef();
    this.plus2Ref = React.createRef();
    this.state = {
      showLines: false,
      zoom: 1.8,
      center: [0, 0],
      description: "",
    };
  }

  minusClicked = () => {
    const targetZoom = Math.max(1.4, this.state.zoom / 2);
    this.setState({ zoom: targetZoom });
    if (targetZoom === 1.4) {
      this.setState({ center: [0, 25] });
    }
  };

  onHover = (country, city, month, year) => {
    this.setState({
      description: city + ", " + country + ", " + month + " " + year,
    });
    this.descriptionRef.current.style.opacity = "1";
  };

  onOut = () => {
    this.descriptionRef.current.style.opacity = "0";
  };

  plusHover = () => {
    this.plus1Ref.current.style.backgroundColor = "rgb(230, 230, 255)";
    this.plus2Ref.current.style.backgroundColor = "rgb(230, 230, 255)";
  };

  minusHover = () => {
    this.minusRef.current.style.backgroundColor = "rgb(230, 230, 255)";
  };

  plusOut = () => {
    this.plus1Ref.current.style.backgroundColor = "rgba(230, 230, 255, 0.5)";
    this.plus2Ref.current.style.backgroundColor = "rgba(230, 230, 255, 0.5)";
  };

  minusOut = () => {
    this.minusRef.current.style.backgroundColor = "rgba(230, 230, 255, 0.5)";
  };

  render() {
    const width = 960,
      height = 540;
    return (
      <div className={classes.wrapper}>
        <div className={classes.header}>Where I've been</div>
        <div
          className={classes.zoomIcon}
          style={{ right: "1vw" }}
          onClick={() =>
            this.setState({ zoom: Math.min(8, this.state.zoom * 2) })
          }
          onMouseEnter={this.plusHover}
          onMouseLeave={this.plusOut}
        >
          <div className={classes.horizontalStripe} ref={this.plus1Ref} />
          <div className={classes.verticalStripe} ref={this.plus2Ref} />
        </div>
        <div
          className={classes.zoomIcon}
          style={{ right: "3vw" }}
          onClick={() => this.minusClicked()}
          onMouseEnter={this.minusHover}
          onMouseLeave={this.minusOut}
        >
          <div className={classes.horizontalStripe} ref={this.minusRef} />
        </div>
        <div>
          <div className={classes.mapContainer}>
            <ComposableMap width={width} height={height}>
              <ZoomableGroup
                zoom={this.state.zoom}
                minZoom={1.2}
                center={[0, 25]}
                translateExtent={[
                  [105, 10],
                  [width - 50, height - 85],
                ]}
              >
                <Geographies
                  className={classes.rsm_geography}
                  geography={geoUrl}
                >
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography key={geo.rsmKey} geography={geo} />
                    ))
                  }
                </Geographies>
                <Annotation
                  subject={[34.959553, 32.808017]}
                  dx={25}
                  dy={-20}
                  curve={0.5}
                  connectorProps={{
                    stroke: "rgb(230, 230, 255)",
                    strokeWidth: 1 + 0.5 / this.state.zoom,
                    strokeLinecap: "round",
                    strokeDasharray: "0.5 4",
                  }}
                >
                  <text
                    x={4}
                    fill="rgb(230, 230, 255)"
                    textAnchor="begin"
                    alignmentBaseline="middle"
                    fontSize={2 + 6 / this.state.zoom + "pt"}
                  >
                    born and raised
                  </text>
                </Annotation>
                {mapData.map(({ country, city, x, y, month, year }, index) => (
                  <Marker
                    key={"place" + index}
                    id={"place" + index}
                    coordinates={[y, x]}
                    onMouseEnter={() =>
                      this.onHover(country, city, month, year)
                    }
                    onMouseLeave={this.onOut}
                  >
                    <g className={classes.dot}>
                      <circle cx="0" cy="0" r={3}></circle>
                    </g>
                  </Marker>
                ))}
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
        <div className={classes.description} ref={this.descriptionRef}>
          {this.state.description}
        </div>
      </div>
    );
  }
}

export default Travels;
