import { useRef } from "react";
import classes from "./TimelineUnit.module.scss";

function TimelineUnit(props) {
  const iconRef = useRef();

  function iconEnter() {
    iconRef.current.style.backgroundColor = "rgb(8, 8, 8)";
  }

  function iconLeave() {
    iconRef.current.style.backgroundColor = props.showContent
      ? "rgb(8, 8, 8)"
      : "rgb(119, 119, 132)";
  }

  function iconClicked() {
    iconRef.current.style.backgroundColor = "rgb(8, 8, 8)";
    props.setActiveUnit(props.index);
  }

  return (
    <div>
      <div
        className={
          props.showContent
            ? `${classes.icon} ${classes.activeIcon}`
            : `${classes.icon} ${classes.nonActiveIcon}`
        }
        style={{
          left: props.left,
        }}
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        onClick={iconClicked}
      >
        <div
          className={classes.iconImage}
          ref={iconRef}
          style={{
            WebkitMaskImage: "url(/icons/" + props.iconUrl + ".png)",
            maskImage: "url(/icons/" + props.iconUrl + ".png)",
            backgroundColor: props.showContent
              ? "rgb(8, 8, 8)"
              : "rgb(119, 119, 132)",
          }}
        />
      </div>
      {props.showContent && (
        <div>
          <div className={classes.arrow} style={{ left: props.left }} />
          <div className={classes.contentBox}>
            <div className={classes.content}>
              <div className={classes.title}>{props.title}</div>
              <div className={classes.desc}>
                {"\n" + props.where + ".\n\n" + props.what}
              </div>
              <div className={classes.time}>{props.when}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TimelineUnit;
