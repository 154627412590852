import React, { useEffect, useState } from "react";

import classes from "./Handler.module.scss";

function Handler(props) {
  const [shouldRender, setRender] = useState(props.show);

  useEffect(() => {
    if (props.show) setRender(true);
  }, [props.show]);

  const onAnimationEnd = () => {
    if (!props.show) setRender(false);
  };

  const hanlderClasses = props.isActive
    ? `${classes.handler} ${classes.active}`
    : `${classes.handler} ${classes.notActive}`;

  function categoryChanged() {
    props.changeCategory(props.index);
    props.setItemIndex(0);
  }
  return (
    shouldRender && (
      <div
        className={hanlderClasses}
        key={"hanlder" + props.title}
        onClick={categoryChanged}
        onAnimationEnd={onAnimationEnd}
        style={{
          transform: "translate(0, " + props.top + ")",
          animation: `${props.show ? classes.slideIn : classes.slideOut} 1s`,
        }}
      >
        {props.title}
      </div>
    )
  );
}

export default Handler;
