import { useState } from "react";

import classes from "./Timeline.module.scss";
import TimelineUnit from "./TimelineUnit";

const timelineUnits = require("../../data/details.json").timeline;

function Timeline(props) {
  const [activeUnit, setActiveUnit] = useState(3);
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>What I did</div>
      <div className={classes.horizontalLine} />
      {timelineUnits.map(({ title, icon, when, where, what }, index) => {
        return (
          <TimelineUnit
            left={(index + 1) * 20 + "%"}
            showContent={index == activeUnit}
            setActiveUnit={setActiveUnit}
            title={title}
            when={when}
            where={where}
            what={what}
            iconUrl={icon}
            index={index}
          />
        );
      })}
    </div>
  );
}

export default Timeline;
